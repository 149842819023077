"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var apps = [
    {
        id: 1,
        name: "igub",
        title: "iGuB",
        description: "Acceso directo al Institut de Seguretat Pública de Catalunya",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.igub2&hl=es",
        ios_url: "https://apps.apple.com/es/app/igub-2-0/id1043481779",
        images: ["apps/igub/mockup"],
        link: "/aplicaciones/oposiciones-guardia-urbana-de-barcelona/igub/",
        screenshots: 6,
    },
    {
        id: 8,
        name: "imossos",
        title: "iMossos",
        description: "Logra formar parte de la Policía de la Generalitat-Mossos d'Esquadra",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.imossos&hl=es",
        ios_url: "https://apps.apple.com/es/app/imossos/id1516244785",
        images: ["apps/imossos/mockup"],
        link: "/aplicaciones/oposiciones-mossos-esquadra/imossos/",
        screenshots: 6,
    },
    {
        id: 3,
        name: "icorreos",
        title: "iCorreos",
        description: "Tu ayuda para preparar la consolidación de empleo a Correos",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.icorreos&hl=es",
        ios_url: "https://apps.apple.com/es/app/icorreos/id1498574168",
        images: ["apps/icorreos/mockup"],
        link: "/aplicaciones/oposiciones-correos/icorreos/",
        screenshots: 5,
        seo: ["/oposiciones-correos/", "/oposiciones-correos-2021/"],
    },
    {
        id: 10,
        name: "ibarcelona",
        title: "iBarcelona",
        description: "Prepara tu oposición al Ayuntamiento de Barcelona",
        large_description: "¿Cuánto sabes sobre Barcelona? Prepara tu oposición al Ayuntamiento de Barcelona",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.ibcn&hl=es",
        ios_url: "https://apps.apple.com/es/app/ibarcelona/id1517761409",
        images: ["apps/ibarcelona/mockup"],
        link: "/aplicaciones/oposiciones-administrativos-ayuntamiento-de-barcelona/ibarcelona/",
        screenshots: 7,
    },
    {
        id: 4,
        name: "ipol",
        title: "iPoL",
        description: "Haz realidad tus sueños y consigue tu plaza como policía local",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.ipol&hl=es",
        ios_url: "https://apps.apple.com/es/app/ipol-opos-polic%C3%ADa-local/id1511139832",
        images: ["apps/ipol/mockup"],
        link: "/aplicaciones/oposiciones-policia-local/ipol/",
        screenshots: 6,
    },
    {
        id: 5,
        name: "igc",
        title: "iGC",
        description: "Haz realidad tus sueños y consigue tu plaza en la Guardia Civil",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.igc&hl=es",
        ios_url: "https://apps.apple.com/es/app/igc-opos/id1511139162",
        images: ["apps/igc/mockup"],
        link: "/aplicaciones/oposiciones-guardia-civil/igc/",
        screenshots: 6,
    },
    {
        id: 2,
        name: "icnp",
        title: "iCNP",
        description: "Prepara tu Oposición a Policía Nacional",
        enabled: true,
        stock: false,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.icnp&hl=es",
        ios_url: "https://apps.apple.com/es/app/ipol-opos-polic%C3%ADa-local/id1491131385",
        images: ["apps/icnp/mockup"],
        link: "/aplicaciones/oposiciones-policia-nacional/icnp/",
        screenshots: 6,
    },
    {
        id: 14,
        name: "isanitarios",
        title: "iSanitarios",
        description: "Haz realidad tu sueño y asegura tu futuro en el campo de la Sanidad.",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.isanitarios&hl=es",
        ios_url: "https://apps.apple.com/es/app/isanitarios/id6464591792",
        images: ["apps/isanitarios/mockup"],
        link: "/aplicaciones/oposiciones-sanitarios/isanitarios/",
        screenshots: 6,
    },
    {
        id: 15,
        name: "ipoliciamadrid",
        title: "iPolicíaMadrid",
        description: "Haz realidad tu sueño y asegura tu futuro en el ámbito de la Seguridad.",
        enabled: true,
        stock: true,
        android_url: "https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.ipoliciamadrid&hl=es",
        ios_url: "https://apps.apple.com/es/app/ipolic%C3%ADamadrid/id6468769920",
        images: ["apps/ipoliciamadrid/mockup"],
        link: "/aplicaciones/oposiciones-policia-madrid/ipoliciamadrid/",
        screenshots: 7,
    },
];
exports.default = apps;
