import React from "react";
import Image from "@components/common/CloudinaryImage";
import { AiOutlineApple, AiOutlineAndroid } from "react-icons/ai";
import CustomAlert from "../common/CustomAlert";
import { Link } from "gatsby";

const Applications = ({ items }) => {
    return (
        <>
            <div className="applications-container">
                {items && items.length > 0 ? (
                    <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
                        {items.map(p => {
                            return <Application key={p.id} item={p} />;
                        })}
                    </div>
                ) : (
                    <CustomAlert type="info" className="mt-5">
                        No hay aplicaciones disponibles.
                    </CustomAlert>
                )}
            </div>
        </>
    );
};

export default Applications;

const Application = ({ item }) => {
    const images = item.images || [];
    const defaultImage = images.length > 0 ? images[0] : "products/common/0";

    return (
        <>
            <div className="rounded shadow hover:shadow-xl overflow-hidden border border-gray-300 border-opacity-50 duration-300">
                <Image alt={item.name} filename={defaultImage} className="h-80" />
                <div className="flex justify-evenly items-center my-8">
                    {item.ios_url && (
                        <a
                            href={item.ios_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-black rounded p-3 hover:bg-opacity-75 duration-300 shadow leading-none"
                            title="Descargar la versión para iOS"
                        >
                            <AiOutlineApple
                                className="w-5 h-5 text-white "
                                style={{ verticalAlign: "middle" }}
                            />
                        </a>
                    )}

                    {item.android_url && (
                        <a
                            href={item.android_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Descargar la versión para Android"
                            className="bg-green-700 rounded p-3 hover:bg-opacity-75 duration-300 shadow leading-none"
                        >
                            <AiOutlineAndroid
                                className="w-5 h-5 text-white "
                                style={{ verticalAlign: "middle" }}
                            />
                        </a>
                    )}
                </div>
                <div>
                    {item.stock ? (
                        <Link
                            to={item.link}
                            title={item.stock ? "Más información" : "Pronto disponible"}
                        >
                            <div className="mx-10">
                                <Image filename={`apps/${item.name}/full-icon`} alt={item.name} />
                            </div>
                            <h3 className="font-montserrat font-slim text-xs uppercase text-gray-600 mt-3 leading-5 m-5 text-center">
                                {item.description}
                            </h3>
                        </Link>
                    ) : (
                        <>
                            <div className="mx-10">
                                <Image filename={`apps/${item.name}/full-icon`} alt={item.name} />
                            </div>
                            <h3 className="font-montserrat font-slim text-xs uppercase text-gray-600 mt-3 leading-5 m-5 text-center">
                                {item.description}
                            </h3>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
