import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Apps from "@base/data/Apps";
import Applications from "@components/sections/Applications";

export default function Index() {
    /*const appsList = Apps.filter(a => a.enabled).sort(function (a, b) {
        return a.name.localeCompare(b.name);
    });*/

    const appsList = Apps.filter(a => a.enabled);

    return (
        <>
            <Layout>
                <Seo
                    title="Nuestras aplicaciones"
                    description="Aplicaciones para Android e iOS para preparar Oposciones de Correos, Cuerpo Nacional de Policía, Guardia Civil, Guàrdia Urbana de Barcelona, Mossos d'Esquadra, etc."
                    tags="ios,android,apps,oposiciones,policia,gub,correos"
                />
                <SmallBanner
                    title="Nuestras aplicaciones"
                    subtitle="Una aplicación para cada Oposición."
                    filename={"bgs/bgIndex"}
                />

                <div className="container py-20">
                    <Title>Aplicaciones disponibles</Title>
                    <Text>
                        Listado con las aplicaciones disponibles en este momento. En breve iremos
                        actualizando con más Oposiciones.
                    </Text>

                    <div className="mt-10">
                        <Applications items={appsList} />
                    </div>
                </div>
            </Layout>
        </>
    );
}
